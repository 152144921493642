<template>
  <section>
    <h1>
      {{ survey.label }}
      <span v-if="!checkStatus('PAT')">({{ this.user.username }})</span>
    </h1>

    <article v-if="survey.documents.length > 0">
      <h2>{{ $t('survey.documents') }}</h2>
      <ul>
        <li v-for="document in survey.documents" :key="document.label">
          <router-link
              :to="{name: 'surveyDocument', params: {surveyId: surveyId, documentId: document.id, name: document.label}}"
          >
            {{ document.label }}
          </router-link>
        </li>
      </ul>
    </article>

    <v-form ref="survey" lazy-validation>
      <div class="loading_container" v-if="!loaded && loading">
        <v-progress-circular indeterminate color="red"/>
      </div>
      <div v-if="!loading && loaded">
        <question-reader
            v-for="(question, index) in questionsLocale"
            :key="index"
            :answers="answers"
            :display="true"
            :question="question"
            :is-read-survey="isReadSurvey"
            :value="answers[question.code]"
            @update-value="onValueUpdated"
            @add-sub-question="onAddSubQuestion"
            @remove-sub-question="onRemoveSubQuestion"
        />
      </div>

      <footer v-if="!loading && loaded" style="clear: both;">
        <v-btn
            color="secondary"
            @click="resetDialog.display = true"
            v-if="canResetSurvey"
            depressed
        >
          {{ $t('survey.actions.buttons.reset') }}
        </v-btn>
        <v-btn color="secondary" @click="redirect(survey.category)" depressed>
          {{ $t('survey.actions.buttons.back') }}
        </v-btn>
        <v-btn
            color="secondary"
            @click="redirect(survey.category)"
            v-if="canResetSurvey"
            depressed
        >
          {{ $t('survey.actions.buttons.return') }}
        </v-btn>
        <v-btn
            class="valid"
            @click="dialog.display = true"
            v-if="!isReadSurvey"
            :disabled="!formIsValid || !canResetSurvey"
            depressed
        >
          {{ $t('survey.actions.buttons.valid') }}
        </v-btn>
        <confirm-dialog
            v-model="dialog.display"
            :title="$t('survey.actions.dialog.title')"
            :body="$t('survey.actions.dialog.body')"
            :has-action="true"
            @cancel="dialog.display = false"
            @valid="onValidSurvey"
        />
        <confirm-dialog
            v-model="resetDialog.display"
            :title="$t('survey.actions.resetDialog.title')"
            :body="$t('survey.actions.resetDialog.body')"
            :has-action="true"
            @cancel="resetDialog.display = false"
            @valid="onResetSurvey"
        />
      </footer>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { myStatus } from '@/store/modules/User'
import QuestionReader from '@/components/Survey/QuestionReader'
import ConfirmDialog from '@/components/Template/ConfirmDialog'
import { regexIsUser, regexUserValue } from '@/store/helpers/survey/regex'
import { getTheUserValue, parseValue } from '@/store/helpers/survey/helpers'
import { answersValidation } from '@/store/helpers/survey/answers'

export default {
  name: 'Survey',

  components: {
    ConfirmDialog,
    QuestionReader,
  },

  props: {
    day: {
      required: false,
      default: 0,
    },
    surveyId: {
      required: true,
    },
    userId: {
      required: true,
    },
  },

  data: () => ({
    answers: {},
    dialog: {
      display: false,
    },
    resetDialog: {
      display: false,
    },
    loaded: false,
    loading: false,
    questionsLocale: [],
    // save if the user start the survey for the first time
    startSurvey: false,
    surveyResultId: null,
  }),

  computed: {
    ...mapGetters([
      'calendar',
      'graphValues',
      'isTechnician',
      'survey',
      'surveyResults',
      'user',
    ]),
    isReadSurvey () {
      if (this.isTechnician) return false

      if (
          this.surveyResults &&
          this.surveyResults[this.survey.code] &&
          this.surveyResults[this.survey.code][this.day]
      ) {
        return this.surveyResults[this.survey.code][this.day].isCompleted
      } else {
        return false
      }
    },
    formIsValid () {
      return this.$refs.survey && this.$refs.survey.validate()
    },

    canResetSurvey () {
      return (
          (this.surveyResults[this.survey.code] !== undefined &&
              this.surveyResults[this.survey.code][this.day] !== undefined &&
              this.surveyResults[this.survey.code][this.day].status !== 2) ||
          this.surveyResultId !== null
      )
    },
  },

  mounted: function () {
    this.resetSurveyResults()
    this.loaded = false
    this.loading = true

    Promise.all([
      this.loadSurvey(this.surveyId),
      this.loadSurveyResults(this.userId),
      this.loadUserCalendar(this.userId),
      this.loadUser({ id: this.userId }),
    ])
           .then(() => {
             document.title = this.survey.label
             const answers =this.surveyResults?.[this.survey.code]?.[this.day]?.answers || null

             this.startSurvey = answers === null

             this.survey.questions.questions.forEach(question => {
               let defaultValue = undefined

               if (question.default !== undefined) {
                 if (regexIsUser.test(question.default)) {
                   let userPath = regexUserValue.exec(question.default).groups
                   userPath = userPath.path
                                      .split(':')
                                      .filter(element => element !== '')
                   defaultValue = getTheUserValue(userPath)

                   // getTheUserValue should always return an object (oniros-back enforces the object format)
                   if (typeof defaultValue === 'object' && defaultValue !== null) {
                     defaultValue =
                         defaultValue.value !== undefined ? defaultValue.value : null
                   }
                 } else if (question.type === 'date' && question.default === 'today') {
                   defaultValue = new Date().toISOString()
                                            .substr(0, 10)
                 } else if (question.type === 'boolean') {
                   defaultValue = !(
                       question.default === undefined || question.default !== true
                   )
                 } else {
                   try {
                     defaultValue = parseValue(question.default)
                   } catch (e) {
                     console.error(e)
                   }
                 }
               }

               if (question.type !== undefined && question.code !== undefined) {
                 if (question.type === 'graph') {
                   if (answers?.GRAPH && typeof answers.GRAPH === 'object') {
                     this.loadMedicines()
                     let meds = []

                     const today = new Date(this.user.inclusionDate)
                     today.setDate(today.getDate() + parseInt(this.day))

                     if (answers.GRAPH) {
                       this.loadCapsules({capsules: answers.GRAPH.meds, today})
                       this.loadSliders({
                         nap: answers.GRAPH.nap,
                         lumino: answers.GRAPH.lumino,
                         sleep: answers.GRAPH.sleep,
                         today,
                       })

                       meds = [...answers.GRAPH.meds]

                       this.$set(this.answers, question.code, {
                         meds: meds,
                         nap:answers.GRAPH.nap,
                         lumino: answers.GRAPH.lumino,
                         sleep: answers.GRAPH.sleep,
                       })
                     }
                   } else {
                     this.resetAgenda()
                     this.loadMedicines()
                     this.$set(this.answers, question.code, {
                       meds: [],
                       sleep: [],
                       lumino: [],
                       nap: [],
                     })
                   }
                 } else if (question.type === 'subquestion') {
                   const subAnswersArray = []

                   if (answers === null) {
                     const subAnswersObject = {}
                     question.subquestions.forEach(subQuestion => {
                       const [key, value] = this.initAnswer(
                           subQuestion,
                           answers,
                           defaultValue,
                       )
                       if (key) subAnswersObject[key] = value
                     })
                     subAnswersArray.push(subAnswersObject)
                   } else {
                     if (answers[question.code] && Array.isArray(answers[question.code])) {
                       answers[question.code]?.forEach(subAnswers => {
                         const subAnswersObject = {}
                         question.subquestions?.forEach(subQuestion => {
                           const [key, value] = this.initAnswer(
                               subQuestion,
                               subAnswers,
                               defaultValue,
                           )
                           if (key) subAnswersObject[key] = value
                         })
                         subAnswersArray.push(subAnswersObject)
                       })
                     }
                   }

                   this.$set(this.answers, question.code, subAnswersArray)
                 } else {
                   const [key, value] = this.initAnswer(
                       question,
                       answers,
                       defaultValue,
                   )
                   if (key) this.$set(this.answers, key, value)
                 }
               }

               // this.$set(this.answers, question.code, answers[question.code])
               this.questionsLocale.push({ ...question })
               this.loaded = true
               this.loading = false
             })
           })
  },

  methods: {
    ...mapActions([
      'buildGraphValues',
      'loadCapsules',
      'loadMedicines',
      'loadSliders',
      'loadSurvey',
      'loadSurveyResults',
      'loadUser',
      'loadUserCalendar',
      'resetAgenda',
      'resetSurvey',
      'resetSurveyResults',
      'saveResult',
      'validSurvey',
    ]),
    /**
     * Initializes answers according to the question type.
     */
    initAnswer (questionInit, answersInit, defaultValue = null) {
      const answerInitialized = [questionInit.code]

      if (
          questionInit.type.includes('multiple') ||
          questionInit.type.includes('list')
      ) {
        answerInitialized.push(
            !!answersInit && `${answersInit[questionInit.code]}`
            ? answersInit[questionInit.code]
            : [],
        )
      } else if (questionInit.type.includes('integer')) {
        answerInitialized.push(
            !!answersInit && `${answersInit[questionInit.code]}`
            ? answersInit[questionInit.code]
            : defaultValue === undefined
              ? 0
              : defaultValue,
        )
      } else if (
          questionInit.type.includes('boolean') ||
          questionInit.type.includes('choice') ||
          questionInit.type.includes('date')
      ) {
        answerInitialized.push(
            !!answersInit && `${answersInit[questionInit.code]}` ? answersInit[questionInit.code] : null,
        )
      } else if (questionInit.type.includes('scale')) {
        answerInitialized.push(
            !!answersInit && `${answersInit[questionInit.code]}` ? answersInit[questionInit.code] : null,
        )
      } else {
        answerInitialized.push(
            !!answersInit && `${answersInit[questionInit.code]}` ? answersInit[questionInit.code] : defaultValue || '',
        )
      }

      return answerInitialized
    },
    onAddSubQuestion (questionCode) {
      const subAnswersObject = {}
      this.questionsLocale
          .find(question => question.code === questionCode)
          .subquestions
          .forEach(subQuestion => {
            const [key, value] = this.initAnswer(subQuestion, null)
            if (key) subAnswersObject[key] = value
          })
      this.answers[questionCode].push(subAnswersObject)
      this.saveResult({
        data: {
          answers: this.answers,
          status: 1,
          day: parseInt(this.day),
          survey: parseInt(this.surveyId),
          onirosuser: parseInt(this.userId),
        },
        surveyResultId: this.surveyResults[this.survey.code][this.day].id,
        userId: this.userId,
      })
    },
    onRemoveSubQuestion ({ questionCode, index }) {
      this.answers[questionCode].splice(index, 1)
      this.saveResult({
        data: {
          answers: this.answers,
          status: 1,
          day: parseInt(this.day),
          survey: parseInt(this.surveyId),
          onirosuser: parseInt(this.userId),
        },
        surveyResultId: this.surveyResults[this.survey.code][this.day].id,
        userId: this.userId,
      })
    },
    onValidSurvey () {
      this.validSurvey({
        resultSurveyId: this.surveyResults[this.survey.code][this.day].id,
        data: {
          answers: answersValidation(
              this.answers,
              this.survey.questions.questions,
              this.surveyResults,
          ),
          status: 2,
          day: parseInt(this.day),
          survey: parseInt(this.surveyId),
          onirosuser: parseInt(this.userId),
        },
      })
          .then(() => {
            this.redirect(this.survey.category)
          })
    },
    onResetSurvey () {
      this.resetSurvey({
        resultSurveyId: this.surveyResults[this.survey.code][this.day].id,
      })
          .then(() => {
            this.redirect(this.survey.category)
          })
    },
    onValueUpdated ({
      questionUpdated,
      newValue,
      isGraph,
      subQuestionOf,
      indexAnswer,
    }) {
      if (isGraph !== undefined && isGraph === true) {
        this.buildGraphValues()
        this.answers.GRAPH = { ...this.graphValues }
      } else if (subQuestionOf) {
        if (Array.isArray(newValue)) {
          this.answers[subQuestionOf][indexAnswer][questionUpdated].slice(
              0,
              this.answers[subQuestionOf][indexAnswer][questionUpdated].length,
          )

          newValue.forEach(value => {
            this.answers[subQuestionOf][indexAnswer][questionUpdated].push(
                value,
            )
          })
        } else {
          this.answers[subQuestionOf][indexAnswer][questionUpdated] = newValue
        }
      } else if (Array.isArray(newValue)) {
        if (Array.isArray(this.answers[questionUpdated])) {
          this.answers[questionUpdated]?.splice(
            0,
            this.answers[questionUpdated]?.length,
          )
          newValue.forEach(value => {
            this.answers[questionUpdated]?.push(value)
          })
        } else {
          this.answers[questionUpdated] = newValue
        }
      } else {
        this.answers[questionUpdated] = newValue
      }

      let data = {
        answers: this.answers,
        status: 1,
        day: parseInt(this.day),
        survey: parseInt(this.surveyId),
        onirosuser: parseInt(this.userId),
      }
      this.surveyResultId = null

      if (this.startSurvey) {
        if (!this.survey.daily && this.survey.milestones.length !== 0) {
          if (this.survey.milestones.length === 1 || parseInt(this.day) === 0) {
            data.milestone = this.survey.milestones[0]
          } else {
            let DxDate = new Date(this.calendar[0].date.getTime())
            DxDate.setDate(DxDate.getDate() + this.day)
            data.milestone = this.calendar.find(milestone => {
              return (
                  milestone.date.toLocaleDateString() ===
                  DxDate.toLocaleDateString()
              )
            }).milestoneSettingsId
          }
        }
      } else {
        if (this.surveyResults[this.survey.code] && this.surveyResults[this.survey.code][this.day]) {
          this.surveyResultId = this.surveyResults[this.survey.code][this.day].id
        }
      }

      this.saveResult({
        data,
        surveyResultId: this.surveyResultId,
        userId: this.userId,
      })
          .then(() => {
            this.startSurvey = false
            if (this.surveyResults[this.survey.code] && this.surveyResults[this.survey.code][this.day]) {
              this.surveyResultId = this.surveyResults[this.survey.code][this.day].id
            }
          })
    },
    checkStatus (status) {
      return myStatus([status])
    },
    redirect (category) {
      switch (category) {
        case 'FORM':
          this.$router.push({
            name: 'SurveyDayList',
            params: { patientId: this.userId, surveyCat: 'FORM' },
          })
          break

        case 'INCLUSION':
          this.$router.push({
            name: 'PatientForms',
            params: { patientId: this.userId, surveyCat: 'INCLUSION' },
          })
          break

        case 'END':
          this.$router.push({
            name: 'PatientForms',
            params: { patientId: this.userId, surveyCat: 'END' },
          })
          break

        case 'SURVEY':
          this.$router.push({
            name: 'SurveyDayList',
            params: { patientId: this.userId, surveyCat: 'SURVEY' },
          })
          break

        default:
          this.$router.push({
            name: 'DashboardPatient',
            params: { patientId: this.userId },
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

section {
  .loading_container {
    width: 100%;
    display: flex;
    justify-content: center;

    .v-progress-circular::v-deep {
      svg {
        circle {
          color: $primaryColor;
        }
      }
    }
  }

  footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    button {
      margin-right: 20px;
      margin-top: 20px;
    }

    .valid {
      background-color: rgba(0, 160, 0, 0.8) !important;
      color: white;
    }
  }

  article {
    background-color: $whiteTransparent;
    padding: 5px 15px 5px 15px;
    margin-bottom: 15px;
    border-radius: 15px;
    clear: both;
  }
}
</style>
