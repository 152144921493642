import User from '@/store/modules/User'
import {
  regexDifferent,
  regexEqual,
  regexIn,
  regexInferior,
  regexInferiorOrEqual,
  regexSuperior,
  regexSuperiorOrEqual,
  regexUserDifferent,
  regexUserEqual,
  regexUserIn,
  regexUserInferior,
  regexUserInferiorOrEqual,
  regexUserSuperior,
  regexUserSuperiorOrEqual,
} from '@/store/helpers/survey/regex'
import { dateDifferent, dateEqual, dateInferior, dateSuperior } from '@/store/helpers/survey/date'

class ConditionCheckError extends Error {
  constructor (message, ...params) {
    super(...params)

    this.name = 'Oniros condition check error'
    this.message = message
    this.date = new Date()
  }
}

/**
 * Parse and return the value with the good type
 *
 * @param {String} value
 */
export const parseValue = value => {
  let valueParsed = null
  const arrayRegex = new RegExp(/\[.*]/)

  if (!isNaN(parseInt(value))) { return parseInt(value) }

  if (value === null || value === "null") { return null; }

  if (arrayRegex.test(value)) {
    const match = value.match(/\[([0-9]+(?:, [0-9]+)*)]/)[1].split(/, /)
    valueParsed = match.map(valueSaved => {
      return isNaN(parseInt(valueSaved)) ? valueSaved : parseInt(valueSaved)
    })

    return valueParsed
  }

  if (value === '[]') { return []; }

  if (typeof value === 'string' || value instanceof String) {
    if (['true','false'].includes(value.toLowerCase())) return value.toLowerCase() === 'true'
    if (value.toLowerCase() === 'today') return new Date()
    if (value === "''") return ''

    // return plain string
    return value
  }

  throw new ConditionCheckError(`helper.js parseValue() : value "${value}" format not found`)
}

/**
 * Returns the condition type
 *
 * @param {String} condition
 * @return {String} type name
 */
export const conditionType = condition => {
  if (condition.includes('>=')) {
    return 'superiorOrEqual'
  } else if (condition.includes('>')) {
    return 'superior'
  } else if (condition.includes('<=')) {
    return 'inferiorOrEqual'
  } else if (condition.includes('<')) {
    return 'inferior'
  } else if (condition.includes('!=')) {
    return 'different'
  } else if (condition.includes('==')) {
    return 'equal'
  } else if (condition.includes('in')) {
    return 'in'
  }

  throw new ConditionCheckError(`In conditionType function, in the helper.js file, for the "if" check: The condition type not found.
  The condition: "${condition}"`)
}

const typeRegex = {
  superiorOrEqual: regexSuperiorOrEqual,
  superior: regexSuperior,
  inferiorOrEqual: regexInferiorOrEqual,
  inferior: regexInferior,
  different: regexDifferent,
  equal: regexEqual,
  in: regexIn,
}

const typeRegexUser = {
  superiorOrEqual: regexUserSuperiorOrEqual,
  superior: regexUserSuperior,
  inferiorOrEqual: regexUserInferiorOrEqual,
  inferior: regexUserInferior,
  different: regexUserDifferent,
  equal: regexUserEqual,
  in: regexUserIn,
}

/**
 * Return an array with the split condition
 *
 * @param {String} condition
 * @param {String} type
 * @return {{survey: String, milestone: String, questionName: String, value: String}}
 */
export const splitQuestionCondition = (condition, type) => {
  return typeRegex[type].exec(condition).groups
}

/**
 * Return an array with the split condition
 *
 * @param {String} condition
 * @param {String} type
 * @return {{path: String[], value: *}}
 */
export const splitUserCondition = (condition, type) => {
  let splitCondition = typeRegexUser[type].exec(condition).groups
  splitCondition.path = splitCondition.path.split(':')
                                      .filter(element => element !== '')
  return splitCondition
}

/**
 *
 * @param {String[]} path
 * @return {*} userValue
 */
export const getTheUserValue = path => {
  const SurveyUser = {...User.state.user}

  return path.reduce((accumulator, currentValue) => {
    return accumulator === undefined ? undefined : accumulator[currentValue]
  }, SurveyUser)
}


/**
 * Check if the first value is superior or equal to the second value
 *
 * @param controlValue
 * @param value
 * @return {boolean}
 */
export const checkSuperiorOrEqual = (controlValue, value) => {
  if (controlValue instanceof Date || value instanceof Date) {
    return dateSuperior(controlValue, value, true)
  }

  return controlValue >= value
}

/**
 * Check if the first value is superior to the second value
 *
 * @param controlValue
 * @param value
 * @return {boolean}
 */
export const checkSuperior = (controlValue, value) => {
  if (controlValue instanceof Date || value instanceof Date) {
    return dateSuperior(controlValue, value, false)
  }

  return controlValue > value
}

/**
 * Check if the first value is inferior or equal to the second value
 *
 * @param controlValue
 * @param value
 * @return {boolean}
 */
export const checkInferiorOrEqual = (controlValue, value) => {
  if (controlValue instanceof Date || value instanceof Date) {
    return dateInferior(controlValue, value, true)
  }

  return controlValue <= value
}

/**
 * Check if the first value is inferior to the second value
 *
 * @param controlValue
 * @param value
 * @return {boolean}
 */
export const checkInferior = (controlValue, value) => {
  if (controlValue instanceof Date || value instanceof Date) {
    return dateInferior(controlValue, value, false)
  }

  return controlValue < value
}

/**
 * Check if the first value is different to the second value
 *
 * @param controlValue
 * @param value
 * @return {boolean}
 */
export const checkDifferent = (controlValue, value) => {
  if (controlValue instanceof Date || value instanceof Date) {
    return dateDifferent(controlValue, value)
  }

  if (typeof controlValue === 'object' || typeof value === 'object') {
    controlValue = JSON.stringify(controlValue)
    value = JSON.stringify(value)
  }

  return controlValue !== value
}

/**
 * Check if the first value is equal to the second value
 *
 * @param controlValue
 * @param value
 * @return {boolean}
 */
export const checkEqual = (controlValue, value) => {
  if (controlValue instanceof Date || value instanceof Date) {
    return dateEqual(controlValue, value)
  }

  if (typeof controlValue === 'object' || typeof value === 'object') {
    controlValue = JSON.stringify(controlValue)
    value = JSON.stringify(value)
  }

  return controlValue === value
}

/**
 * Check if the first value is include in the second value
 *
 * @param controlValue
 * @param value
 * @return {boolean}
 */
export const checkIn = (controlValue, value) => {
  return controlValue.includes(value)
}

export const checkList = {
  superiorOrEqual: checkSuperiorOrEqual,
  superior: checkSuperior,
  inferiorOrEqual: checkInferiorOrEqual,
  inferior: checkInferior,
  different: checkDifferent,
  equal: checkEqual,
  in: checkIn,
}
