<template>
  <article v-if="isDisplayed" :class="{ question__transparent: isTransparent }" :style="computedWidth + computedIndent">
    <graph v-if="question.graph !== undefined"
           :answers="answers"
           :is-read-survey="isReadSurvey"
           @update-value="onUpdateGraphValue"
    />
    <subquestion v-else-if="question.type === 'subquestion'"
                 :question="question"
                 :answers="answers"
                 :is-read-survey="isReadSurvey"
                 @update-value="$emit('update-value', $event)"
                 @add-sub-question="$emit('add-sub-question', $event)"
                 @remove-sub-question="$emit('remove-sub-question', $event)"
    />
    <survey-alert v-else-if="question.type === 'alert'"
                  :question="question"
    />
    <survey-message v-else-if="question.type === 'message'"
                    :question="question"
    />
    <survey-title v-else-if="question.type === 'title'"
                  :question="question"
    />
    <div v-else>
      <h2>{{ question.label }}</h2>
      <component
          :is="questionType(question.type)"
          :question-id="question.code"
          :value="value"
          :choices="question.choices"
          :is-read-survey="isReadSurvey"
          :options="options"
          @update-value="onUpdateValue"
      />
    </div>
  </article>
</template>

<script>
import SurveyAlert from '@/components/Survey/Reader/Alert'
import SurveyMessage from '@/components/Survey/Reader/Message'
import SurveyTitle from '@/components/Survey/Reader/Title'

import { mapGetters } from 'vuex'
import { testCondition } from '@/store/helpers/survey/conditions'

export default {
  name: 'QuestionReader',

  components: {
    SurveyAlert,
    SurveyMessage,
    SurveyTitle,
    boolean: () => import('@/components/Survey/Reader/Boolean'),
    choice: () => import('@/components/Survey/Reader/Choice'),
    date: () => import('@/components/Survey/Reader/Date'),
    duration: () => import('@/components/Survey/Reader/Time'),
    duration_m: () => import('@/components/Survey/Reader/DurationM'),
    float: () => import('@/components/Survey/Reader/Float'),
    graph: () => import('@/components/Survey/Reader/Graph'),
    integer: () => import('@/components/Survey/Reader/Integer'),
    multiple: () => import('@/components/Survey/Reader/Multiple'),
    list: () => import('@/components/Survey/Reader/List'),
    scale: () => import('@/components/Survey/Reader/Scale'),
    subquestion: () => import('@/components/Survey/Reader/Sub'),
    text_free: () => import('@/components/Survey/Reader/TextFree'),
    text_input: () => import('@/components/Survey/Reader/TextInput'),
    time_interval: () => import('@/components/Survey/Reader/TimeInterval'),
    time_value: () => import('@/components/Survey/Reader/Time'),
  },

  props: {
    answers: {
      required: true,
      type: Object,
    },
    display: {
      required: true,
      type: Boolean,
    },
    isReadSurvey: {
      required: true,
      type: Boolean,
    },
    question: {
      required: true,
      type: Object,
    },
    value: {
      required: true,
    },
  },

  computed: {
    ...mapGetters([
      'surveyResults',
    ]),
    isDisplayed () {
      // if no condition component displayed
      if (this.question.if === undefined) return true

      return testCondition(this.question.if, this.answers)
    },
    options () {
      let opts = {
        'layout': 'column',
        'readonly': false,
        'mandatory': false,
      }

      // readonly question
      if (this.question.readonly === 1) {
        opts['readonly'] = true
      }

      // mandatory
      if (this.question.mandatory !== undefined && this.question.mandatory === true) {
        opts['mandatory'] = true
      }

      if (this.question.min !== undefined && Number.isInteger(this.question.min)) {
        opts['min'] = this.question.min
      }

      if (this.question.max !== undefined && Number.isInteger(this.question.max)) {
        opts['max'] = this.question.max
      }

      if (this.question.valid !== undefined) {
        try {
          opts['valid'] = JSON.parse(this.question.valid)
        } catch (error) {
          console.error("Cannot parse JSON from 'valid' option : " + this.question.valid)
        }
      }

      // Single choice for now but we may have more in the future
      if (this.question.layout === 'line') {
        opts['layout'] = 'line'
      }

      if (this.question.type.includes('list')) {
        let result = this.question.type.split(/^list\(type=([a-z]*), size={(Q|V)+:(\w*):(\w*):([\w.]*)}\)$/)
        // eslint-disable-next-line no-unused-vars
        let [start, type, typeQuestion, survey, milestone, questionName] = result

        if (typeQuestion === 'Q') {
          return {
            ...opts, ...{
              type,
              size: this.answers[questionName],
            },
          }
        }
      } else if (this.question.type.includes('scale')) {
        return { ...opts, .../^scale\((?<min>\d*),(?<max>\d*),(?<step>\d*)\)$/.exec(this.question.type).groups }
      }

      return {
        ...opts, ...{
          type: null,
          size: 0,
        },
      }
    },
    isTransparent () {
      return this.question.type === 'alert' || this.question.type === 'subquestion'
    },
    computedWidth () {
      // No additional styles when level is not specified
      if (this.question.level === undefined) {
        return 'float: right; clear: both; width: 94%;'
      }

      let level = this.question.level
      if (level > 3) { level = 3 } // max level : 3
      let width_v = 94 + (4 - level) * 2 // Add 2% width for each level (level 1: +6%, level 2: +4%, level 3: +2%)

      return 'float: right; clear: both; width: ' + width_v + '%;'
    },
    computedIndent () {
      if (this.question.indent === undefined) {
        return ''
      }

      let indent_v = 94 - 4 * this.question.indent // remove 4% per indent level

      return 'width: ' + indent_v + '%'
    },
  },

  methods: {
    questionType (type) {
      return type.split('(')[0]
    },
    onUpdateGraphValue (newValue) {
      this.$emit(
          'update-value',
          {
            questionUpdated: this.question.code,
            newValue: newValue,
            isGraph: true,
          },
      )
    },
    onUpdateValue (newValue) {
      this.$emit('update-value', { questionUpdated: this.question.code, newValue: newValue })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

article {
  background-color: $whiteTransparent;
  padding: 5px 15px 5px 15px;
  margin-bottom: 15px;
  border-radius: 15px;
  clear: both;
}

.question__transparent {
  background: none;
  margin-bottom: 0;
}
</style>
