  <template>
  <div>
    <v-alert :type=alert_type :color=computedColorName>
      {{ question.label }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'SurveyAlert',

  props: {
    question: {
      required: true,
      type: Object,
    },
  },

  computed: {
    alert_type () {
      if ( this.question.alert_type !== undefined) {
        return this.question.alert_type
      }
      else {
        return 'error'
      }
    },
    computedColorName () {
      switch(this.question.alert_type) {
        case "error":
          return "rgba(255,52,52,0.6)";

        case "success":
          return "rgba(76,175,50,0.6)";

        case "info":
          return "rgba(21,96,243,0.6)";

        case "warning":
          return "rgba(251,140,00,0.6)";

        default:
          return "rgba(255,52,52,0.6)";
      }
    }
  },

}
</script>

<style lang="scss" scoped>
div {
  font-weight: bold;
  font-size: 16pt;
}
</style>
