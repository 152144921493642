import { testCondition } from '@/store/helpers/survey/conditions'

const isNA = answer => {
  if (answer === null || answer === undefined || answer === '') {
    return 'NA'
  }

  return answer
}

export const answersValidation = (answers, questions) => {
  let answersValidated = {}
  questions.forEach(question => {
    // la question est-elle conditionnelle
    if (question.code) {
      if (question.if) {
        // devait-elle être renseignée
        if (testCondition(question.if, answers)) {
          answersValidated[question.code] = isNA(answers[question.code])
        } else {
          answersValidated[question.code] = 'NC'
        }
      } else {
        answersValidated[question.code] = isNA(answers[question.code])
      }
    }
  })

  return answersValidated
}
