<template>
  <header>
    <h2>{{ question.label }}</h2>
  </header>
</template>

<script>
export default {
  name: 'SurveyTitle',

  props: {
    question: {
      required: true,
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  font-weight: bold;
}
</style>
