import Survey from '@/store/modules/Survey'
import {
  checkList,
  conditionType,
  getTheUserValue,
  parseValue,
  splitQuestionCondition,
  splitUserCondition,
} from '@/store/helpers/survey/helpers'
import { regexIsUser } from '@/store/helpers/survey/regex'

/**
 * Checks if the condition is a regular condition (Q vs USER)
 * Splits the condition to get the value to be tested
 * Get the control value in function of the regex condition
 * Checks if the condition is verified with the control value and the value
 *
 * @param {String} condition
 * @param {Object[]} answers
 * @return {boolean}
 */
const checkCondition = (condition, answers) => {
  try {
    const condType = conditionType(condition)
    const isUserCondition = regexIsUser.test(condition)
    let splitCondition = null
    let controlValue = null

    splitCondition = isUserCondition
                     ? splitUserCondition(condition, condType)
                     : splitQuestionCondition(condition, condType)

    splitCondition.value = parseValue(splitCondition.value)

    if (isUserCondition) {
      controlValue = getTheUserValue(splitCondition.path)

      // getTheUserValue should always return an object (oniros-back enforces the object format)
      if (typeof controlValue === 'object' && controlValue !== null && !(controlValue instanceof Date)) {
        controlValue = controlValue.value !== undefined ? controlValue.value : null
      }
    } else {
      if (splitCondition.milestone) {
        console.error('Oniros condition check error: The specific milestone is not considered')
      }

      if (splitCondition.survey) {
        const surveyResults = Survey.state.surveyResults[splitCondition.survey]

        if (surveyResults !== undefined) {
          const surveyResultsKeys = Object.keys(surveyResults)
          controlValue = surveyResults[surveyResultsKeys[surveyResultsKeys.length - 1]].answers[splitCondition.questionName]
        } else {
          controlValue = undefined
        }
      } else {
        controlValue = answers[splitCondition.questionName]
      }
    }

    return checkList[condType](controlValue, splitCondition.value)
  } catch (e) {
    return false
  }
}

/**
 * Checks if the condition is multi condition or a unique condition
 * Splits if is a multi condition
 * Returns the check condition value
 *
 * @param {String} conditionTested
 * @param {Object[]} answers
 * @return {Boolean}
 */
export const testCondition = (conditionTested, answers) => {
  if (conditionTested.includes('and')) {
    const conditions = conditionTested.split(' and ')

    return conditions.every(condition => {
      return checkCondition(condition, answers)
    })
  } else if (conditionTested.includes('or')) {
    const conditions = conditionTested.split(' or ')

    return conditions.some(condition => {
      return checkCondition(condition, answers)
    })
  }

  return checkCondition(conditionTested, answers)
}
