<template>
  <div>
    {{ question.label }}
  </div>
</template>

<script>
export default {
  name: 'SurveyMessage',

  props: {
    question: {
      required: true,
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
div {
  font-weight: normal;
  font-size: 14pt;
}
</style>
