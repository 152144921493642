export const regexIsUser = /{USER:/

export const regexSuperiorOrEqual = /^\{Q:(?<survey>\w*):(?<milestone>\w*):(?<questionName>[\w.]*)} ?>= ?(?<value>.*)$/
export const regexSuperior = /^\{Q:(?<survey>\w*):(?<milestone>\w*):(?<questionName>[\w.]*)} ?> ?(?<value>.*)$/
export const regexInferiorOrEqual = /^\{Q:(?<survey>\w*):(?<milestone>\w*):(?<questionName>[\w.]*)} ?<= ?(?<value>.*)$/
export const regexInferior = /^\{Q:(?<survey>\w*):(?<milestone>\w*):(?<questionName>[\w.]*)} ?< ?(?<value>.*)$/
export const regexDifferent = /^\{Q:(?<survey>\w*):(?<milestone>\w*):(?<questionName>[\w.]*)} ?!= ?(?<value>.*)$/
export const regexEqual = /^\{Q:(?<survey>\w*):(?<milestone>\w*):(?<questionName>[\w.]*)} ?== ?(?<value>.*)$/
export const regexIn = /^(?<value>.*) in \{Q:(?<survey>\w*):(?<milestone>\w*):(?<questionName>[\w.]*)}$/

export const regexUserSuperiorOrEqual = /^\{USER(?<path>(?::\w*)*)} ?>= ?(?<value>.*)$/
export const regexUserSuperior = /^\{USER(?<path>(?::\w*)*)} ?> ?(?<value>.*)$/
export const regexUserInferiorOrEqual = /^\{USER(?<path>(?::\w*)*)} ?<= ?(?<value>.*)$/
export const regexUserInferior = /^\{USER(?<path>(?::\w*)*)} ?< ?(?<value>.*)$/
export const regexUserDifferent = /^\{USER(?<path>(?::\w*)*)} ?!= ?(?<value>.*)$/
export const regexUserEqual = /^\{USER(?<path>(?::\w*)*)} ?== ?(?<value>.*)$/
export const regexUserIn = /^(?<value>.*) in \{USER(?<path>(?::\w*)*)}$/

export const regexUserValue = /^\{USER(?<path>(?::\w*)*)}$/